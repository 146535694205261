<template>
  <div class="home contained">
    <img
      class="logo"
      alt="MSI logo"
      src="@/assets/images/logo.png"/>

    <b-jumbotron
      class="page-header"
      :header="jumbotron.header"
      :lead="jumbotron.lead"/>

    <b-card
      class="mt-4">
      <div class="card-head">
        <b-card-title>
          {{ selectCountryCard.countryLabel }}
        </b-card-title>
      </div>

      <b-form-select
        v-model="selectedCountry"
        :options="countries"
        value-field="countryId"
        text-field="name"
        required
        class="fa">
        <template #first>
          <b-form-select-option value="null" disabled class="fa">
            Please select an option
          </b-form-select-option>
        </template>
      </b-form-select>

      <div
        class="card-head"
        v-if="selectedCountry && programmes.length > 1">
        <b-card-title>
          {{ selectCountryCard.projectLabel }}
        </b-card-title>
      </div>

      <b-form-select
        v-if="selectedCountry && programmes.length > 1"
        v-model="selectedProgramme"
        :options="programmes"
        value-field="programmeId"
        text-field="name">
        <template #first>
          <b-form-select-option value="null" disabled>
            Please select an option
          </b-form-select-option>
        </template>
      </b-form-select>

      <b-button
        block
        variant="secondary"
        class="leading"
        :disabled="loading || !selectedCountry || !selectedProgramme"
        @click="save">
        <font-awesome-icon :icon="['fas', 'arrow-right']" />
        <span class="button-text">{{ selectCountryCard.buttonText }}</span>
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { find, first } from 'lodash';

export default {
  name: 'CountryProgramSelect',

  data: () => ({
    loading: true,
    jumbotron: {
      header: 'Please select a country and programme',
    },
    selectCountryCard: {
      countryLabel: 'Select a country',
      projectLabel: 'Select a programme',
      buttonText: 'continue',
    },
  }),

  computed: {
    ...mapGetters('country', [
      'countries',
      'country',
      'countryById',
    ]),

    ...mapGetters('programme', [
      'programmes',
      'programme',
    ]),

    ...mapGetters([
      'isReset',
    ]),

    selectedCountry: {
      get() {
        return (this.country && this.country.countryId) ? this.country.countryId : null;
      },

      set(value) {
        const country = find(this.countries, { countryId: value });
        if (country) {
          this.setCountry(country);
          this.setProgrammes(country && country.programmes ? country.programmes : null);

          if (!find(this.programmes, { programmeId: this.selectedProgramme })) {
            this.setProgramme(null);
          }

          if (country.programmes && country.programmes.length === 1) {
            this.setProgramme(first(country.programmes));
            this.save();
          }
        }
      },
    },

    selectedProgramme: {
      get() {
        return (this.programme && this.programme.programmeId) ? this.programme.programmeId : null;
      },

      set(value) {
        this.setProgramme(find(this.programmes, { programmeId: value }));
      },
    },

    programOptions() {
      const selectedCountry = find(this.countries, { countryId: this.selectedCountry });
      return selectedCountry ? selectedCountry.programmes : null;
    },
  },

  mounted() {
    this.getCountries();
  },

  methods: {
    ...mapActions('country', [
      'loadCountries',
    ]),

    ...mapActions([
      'getAllData',
      'resetEntireProgramme',
    ]),

    ...mapMutations('country', [
      'setCountry',
    ]),

    ...mapMutations('programme', [
      'setProgrammes',
      'setProgramme',
    ]),

    async getCountries() {
      this.loading = true;
      if (this.isReset) {
        await this.resetEntireProgramme();
      }
      await this.loadCountries();
      this.loading = false;
    },

    async save() {
      this.loading = true;
      await this.getAllData(this.selectedProgramme);
      this.loading = false;
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
  .logo {
    height: 3rem;
    margin-bottom: 1.5rem;
  }

  .card .card-body .card-head .card-title {
    margin-left: 0;
  }
</style>
